<template>
  <v-row>
    <v-col cols="12" md="8" offset-md="2">
      <v-card>
        <v-card-title class="d-flex justify-center" primary-title>
          Configurações do Aplicativo
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <app-text-field
                prepend-inner-icon="mdi-link"
                v-model="form.app_clinic_slug"
                label="Link"
                type="url"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-6 d-flex flex-column">
              <label for="url"> Link para o aplicativo</label>
              <a id="url" @click="handleCopyLink()">
                {{ `${appClinicBaseUrl}${form.app_clinic_slug} ` }}
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-text-field
                v-model="form.about"
                label="Descrição da Clinica"
              />
            </v-col>
          </v-row>
          <h3 class="mt-10 mb-4"></h3>
          <v-row>
            <v-col>
              <v-menu offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="primary_color"
                    class="mr-2"
                    :color="form.app_clinic_primary_color"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-color-picker
                  hide-canvas
                  v-model="form.app_clinic_primary_color"
                  hide-inputs
                  hide-mode-switch
                  hide-sliders
                  show-swatches
                  swatches-max-height="300px"
                />
              </v-menu>
              <label for="primary_color"> Cor Primária</label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-switch
            label="Ativar  aplicativo"
            v-model="form.app_clinic_status"
            :true-value="1"
            :false-value="0"
          />
          <v-spacer />
          <v-btn @click="$router.go(-1)" text> Cancelar </v-btn>
          <v-btn @click="update()" class="mr-2" color="primary"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      colorsOptions: [
        { value: null, key: "primary", label: "Primária" },
        { value: null, key: "secondary", label: "Secundária" },
        // { value: null, key: "success", label: "Sucesso" },
        // { value: null, key: "info", label: "Informação" },
        // { value: null, key: "accent", label: " Texto" },
        // { value: null, key: "error", label: " Error" },
      ],

      form: {},

      baseForm: {
        app_clinic_slug: null,
        app_clinic_primary_color: "#8b5aff",
      },

      // swatches: [
      //   ["#F64272", "#F6648B", "#F493A7", "#F891A6", "#FFCCD5"],
      //   ["#8b5aff", "#a27bff", "#b99cff", "#d0bdff", "#e8deff"],
      //   ["#51e5db", "#74ebe3", "#96f0ea", "#b9f5f1", "#dcfaf8"],
      //   ["#ffa51a", "#ffb748", "#ffc976", "#ffdba3", "#ffedd1"],
      // ],
    };
  },
  computed: {
    appClinicBaseUrl() {
      return process.env.VUE_APP_CLINIC_BASE_URL;
    },

    company() {
      return this.$store.state.auth.company;
    },
  },

  mounted() {
    this.form = JSON.parse(JSON.stringify(this.company));
  },

  methods: {
    update() {
      this.$http
        .update("company/company", this.company.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$loading.finish();
      this.$snackbar({
        message: "Configurações salvas com sucesso!",
        color: "success",
        timeout: 1000,
        showClose: false,
      });
    },
    handleCopyLink() {
      navigator.clipboard
        .writeText(`${this.appClinicBaseUrl}${this.form.app_clinic_slug} `)
        .then((response) => {
          this.$snackbar({
            message: "Link copiado com sucesso!",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
        })
        .catch((error) => {});
    },
  },
};
</script>

<style>
</style>